import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '@moonshineragency/ui/src/components/Popout/Popout.module.scss';

const Popout = ({ children, className, arrowPosition }) => (
  <div
    className={classNames(
      className,
      styles.container,
      styles[arrowPosition],
      'p-2',
    )}
  >
    {children && children}
  </div>
);

Popout.propTypes = {
  arrowPosition: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

Popout.defaultProps = {
  arrowPosition: 'center',
  children: null,
  className: '',
};

export default Popout;
