import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Popout from '@moonshineragency/ui/src/components/Popout/Popout';
import { IconButton } from '@moonshineragency/ui/src/components/Button/Button';

import styles from '@moonshineragency/ui/src/components/Navigation/Navigation.module.scss';

const NavigationItem = ({ label, icon, className }) => {
  return (
    <li className="nav-item">
      <IconButton
        noBackground
        className={classNames(className, 'nav-link font-weight-bold')}
      >
        {icon && icon}
        {label}
      </IconButton>
    </li>
  );
};

const NavigationDropdown = ({
  label,
  icon,
  children,
  className,
  renderArrow,
}) => {
  return (
    <li
      className={classNames(
        'nav-item',
        'dropdown',
        'mr-3',
        styles.dropdown,
        className,
      )}
    >
      <IconButton
        noBackground
        type="button"
        className={classNames(
          styles.navItem,
          'nav-link',
          'btn',
          'btn-link',
          'font-weight-bold',
        )}
        id="navbarDropdown"
        data-toggle="dropdown"
        aria-haspopup
        aria-expanded={false}
      >
        {icon && icon}
        {label}
        {renderArrow && (
          <span
            className={classNames(styles.dropdownArrow, 'icon chevron-right')}
          />
        )}
      </IconButton>
      <Popout className="dropdown-menu" arrowPosition="left">
        <ul className={classNames(styles.navList, 'pl-1')}>{children}</ul>
      </Popout>
    </li>
  );
};

const Navigation = () => {
  return (
    <div className="bg-primary sticky-top">
      <div className="container">
        <nav className="navbar navbar-expand d-print bg-primary">
          <div className="show" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <NavigationItem
                className={classNames(styles.navItem, 'mr-2')}
                label="Meine Aufträge"
                icon={<span className="icon list mt-1 mr-3" />}
              />
              <NavigationDropdown
                label="Neuer Auftrag"
                icon={<span className="icon wrench-1 mt-1 mr-3" />}
              >
                <NavigationItem
                  className={styles.menuItem}
                  label="Neuanschluss / Wiedereinschaltung"
                  icon={<span className="icon units mt-1 mr-3" />}
                />
                <NavigationItem
                  className={styles.menuItem}
                  label="Änderung"
                  icon={<span className="icon change mt-1 mr-3" />}
                />
                <NavigationItem
                  className={styles.menuItem}
                  label="Erzeugungsanlage / Batteriespeicher hinzufügen"
                  icon={<span className="icon in mt-1 mr-3" />}
                />
                <NavigationItem
                  className={styles.menuItem}
                  label="Abschaltung"
                  icon={<span className="icon off mt-1 mr-3" />}
                />
              </NavigationDropdown>
              <NavigationDropdown
                label="Fertigstellungsmeldung"
                icon={<span className="icon task-finished mt-1 mr-3" />}
              >
                <NavigationItem
                  className={styles.menuItem}
                  label="Antrag zur Inbetriebnahme (Verbraucher)"
                  icon={<span className="icon meter mt-1 mr-3" />}
                />
                <NavigationItem
                  className={styles.menuItem}
                  label="Installationsdokument (Erzeugungsanlage / Batteriespeicher)"
                  icon={<span className="icon in mt-1 mr-3" />}
                />
                <NavigationItem
                  className={styles.menuItem}
                  label="Periodische Überprüfung Netzentkopplungsschutz"
                  icon={<span className="icon time mt-1 mr-3" />}
                />
              </NavigationDropdown>
              <NavigationItem
                className={styles.navItem}
                label="Handbücher"
                icon={<span className="icon book mt-1 mr-3" />}
              />
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

NavigationItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.node.isRequired,
};

NavigationItem.defaultProps = {
  className: '',
  icon: null,
};

NavigationDropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  label: PropTypes.node.isRequired,
  renderArrow: PropTypes.bool,
};

NavigationDropdown.defaultProps = {
  ...NavigationItem.defaultProps,
  renderArrow: true,
};

export { Navigation as default, NavigationItem, NavigationDropdown };
