import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import {
  unstable_useFormState as useFormState,
  unstable_Form as BaseForm,
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
  unstable_FormMessage as FormMessage,
  unstable_FormRadioGroup as FormRadioGroup,
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormGroup as FormGroup,
  unstable_FormRadio as FormRadio,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';
import classNames from 'classnames';

import Button from '@moonshineragency/ui/src/components/Button/Button';

import styles from '@moonshineragency/ui/src/components/Forms/Forms.module.scss';

const FormContext = React.createContext();

const isChecked = (form, name, inputName) =>
  form.values[name] && form.values[name].includes(inputName);

const Form = ({ initialValues, onValidate, onSubmit, ...props }) => {
  const form = useFormState({ values: initialValues, onValidate, onSubmit });
  const value = React.useMemo(() => form, [form]);
  return (
    <FormContext.Provider value={value}>
      <BaseForm {...form} {...props} />
    </FormContext.Provider>
  );
};

const Field = ({
  name,
  label,
  hint,
  className,
  error,
  additionalElements,
  ...props
}) => {
  const form = React.useContext(FormContext);
  return (
    <div
      className={classNames(
        'input-group d-flex flex-wrap align-items-center',
        className,
        {
          [styles.error]: error,
        },
      )}
    >
      {label && (
        <FormLabel
          {...form}
          className={classNames('font-weight-bold', styles.fieldLabel)}
          name={name}
          label={label}
        />
      )}
      <FormInput
        {...form}
        className={classNames(styles.formControl, 'form-control')}
        {...props}
        name={name}
      />
      {additionalElements && (
        <div className={styles.fieldChildren}>{additionalElements}</div>
      )}
      {hint && (
        <small
          className={classNames(styles.hint, 'form-text text-muted')}
          id="passwordHelpBlock"
        >
          {hint}
        </small>
      )}
      <FormMessage as="small" className="text-danger" {...form} name={name} />
    </div>
  );
};

const FieldGroup = ({ items, name, label, className }) => {
  const form = React.useContext(FormContext);
  return (
    <>
      {label && (
        <FormLabel
          {...form}
          className="font-weight-bold"
          name={name}
          label={label}
        />
      )}
      <div className={classNames(styles.fieldGroup, 'd-flex', className)}>
        {items.map(({ children, ...i }) => (
          <Field key={i.name} {...i} additionalElements={children} />
        ))}
      </div>
    </>
  );
};

const RadioGroup = ({ items, name, label, labelClassName = '', inline }) => {
  const form = React.useContext(FormContext);
  return (
    <FormRadioGroup {...form} name={name}>
      {label && (
        <FormLabel
          {...form}
          className="label font-weight-bold"
          as="legend"
          name={name}
        >
          {label}
        </FormLabel>
      )}
      {items.map(({ label: radioLabel, name: radioName }) => (
        <label
          key={radioName}
          className={classNames(
            'custom-control custom-radio custom-control-inline py-2',
            inline ? 'd-inline-flex' : 'd-flex',
            styles.radio,
            styles.labelDark,
          )}
        >
          <FormRadio
            {...form}
            className="custom-control-input"
            name={radioName}
            value={radioName}
          />
          <span
            className={classNames(
              'custom-control-label',
              styles.labelDark,
              labelClassName,
            )}
          >
            {radioLabel}
          </span>
        </label>
      ))}
    </FormRadioGroup>
  );
};

const CheckboxGroup = ({
  items,
  name,
  label,
  labelClassName = '',
  type = 'checkbox',
  inline,
  contentClassName,
}) => {
  const form = React.useContext(FormContext);
  return (
    <FormGroup {...form} name={name} className={styles[type]}>
      {label && (
        <FormLabel
          {...form}
          className="label font-weight-bold"
          as="legend"
          name={name}
        >
          {label}
        </FormLabel>
      )}
      <div className={classNames(styles.content, contentClassName)}>
        {items.map(({ label: radioLabel, name: radioName }) => (
          <label
            key={radioName}
            className={classNames(
              styles.radio,
              styles.labelDark,
              inline ? 'd-inline-flex' : 'd-flex',
              {
                'custom-control custom-checkbox custom-control-inline':
                  type === 'checkbox',
              },
            )}
          >
            <FormCheckbox
              {...form}
              className={classNames({
                'custom-control-input': type === 'checkbox',
              })}
              name={name}
              value={radioName}
            />
            <span
              className={classNames(
                labelClassName,
                type === 'pill' && styles.badge,
                isChecked(form, name, radioName) &&
                  type === 'pill' &&
                  styles.activeBadge,
                {
                  'custom-control-label': type === 'checkbox',
                  'badge badge-pill p-2 mr-1': type === 'pill',
                },
              )}
            >
              {type === 'pill' && (
                <span
                  className={classNames(
                    'icon check',
                    styles.pillIcon,
                    !isChecked(form, name, radioName) && styles.hidePill,
                  )}
                />
              )}
              <span className={styles.pillLabel}>{radioLabel}</span>
            </span>
          </label>
        ))}
      </div>
    </FormGroup>
  );
};

const SubmitButton = props => {
  const form = React.useContext(FormContext);
  return <FormSubmitButton as={Button} {...form} {...props} />;
};

const FormNavigation = ({ back }) => {
  return (
    <div className="d-flex align-items-center justify-content-between align-items-stretch">
      <Button
        theme="secondary"
        className={classNames(styles.formNavigation, 'mr-2')}
        onClick={() => navigate(back)}
      >
        <span className="icon chevron-left mr-2" /> Zurück
      </Button>
      <SubmitButton className={styles.formNavigation} theme="primary">
        Weiter <span className="icon chevron-right ml-2" />
      </SubmitButton>
    </div>
  );
};

Form.propTypes = {
  initialValues: PropTypes.shape(),
  onSubmit: PropTypes.func,
  onValidate: PropTypes.func,
};

Form.defaultProps = {
  initialValues: {},
  onSubmit: () => null,
  onValidate: () => null,
};

Field.propTypes = {
  className: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

Field.defaultProps = {
  className: '',
  hint: '',
  label: '',
};

FieldGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({ ...Field.propTypes, children: PropTypes.node }),
  ).isRequired,
};

FieldGroup.defaultProps = {
  label: null,
  className: '',
};

const radioCheckPropTypes = {
  contentClassName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string || PropTypes.shape(),
      name: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  inline: PropTypes.bool,
};

const radioCheckDefaultProps = {
  contentClassName: '',
  label: '',
  labelClassName: '',
  inline: false,
};

RadioGroup.propTypes = radioCheckPropTypes;

RadioGroup.defaultProps = radioCheckDefaultProps;

CheckboxGroup.propTypes = { ...radioCheckPropTypes, type: PropTypes.string };

CheckboxGroup.defaultProps = { ...radioCheckDefaultProps, type: 'checkbox' };

FormNavigation.propTypes = {
  back: PropTypes.string,
};

FormNavigation.defaultProps = {
  back: '',
};

export {
  Form as default,
  Field,
  FieldGroup,
  SubmitButton,
  RadioGroup,
  CheckboxGroup,
  FormNavigation,
};
