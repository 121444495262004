import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '@moonshineragency/ui/src/components/Button/Button.module.scss';

const BasicButton = ({ children, className, ...props }) => (
  <button
    type="button"
    className={classNames(className, styles.general, 'btn d-flex')}
    {...props}
  >
    {children}
  </button>
);

const Button = ({ children, className, theme, size = '100', ...props }) => (
  <BasicButton
    className={classNames(
      className,
      styles.button,
      styles[`${theme}Button`],
      styles[`height${size}`],
      `btn-${theme}`,
    )}
    {...props}
  >
    {children}
  </BasicButton>
);

const IconButton = ({
  className,
  theme,
  children,
  size = '100',
  round = false,
  small = false,
  noBackground = false,
  ...props
}) => (
  <BasicButton
    className={classNames(
      className,
      styles.iconButton,
      (noBackground && styles[`${theme}ButtonNoBackground`]) ||
        styles[`${theme}Button`],
      ((small || round) && styles[`heightSmall${size}`]) ||
        styles[`height${size}`],
      round && styles.round,
      small && styles.small,
    )}
    {...props}
  >
    {children}
  </BasicButton>
);

const LinkButton = ({ className, theme, children, size = '100', ...props }) => (
  <BasicButton
    className={classNames(
      className,
      styles.link,
      styles[`${theme}Link`],
      styles[`height${size}`],
    )}
    {...props}
  >
    {children}
  </BasicButton>
);

const buttonProps = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.string,
};

const buttonDefaultProps = {
  children: null,
  className: '',
  size: '100',
  theme: 'primary',
};

BasicButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

BasicButton.defaultProps = {
  children: null,
  className: '',
};

Button.propTypes = buttonProps;

Button.defaultProps = buttonDefaultProps;

IconButton.propTypes = {
  ...buttonProps,
  noBackground: PropTypes.bool,
  round: PropTypes.bool,
  small: PropTypes.bool,
};

IconButton.defaultProps = {
  ...buttonDefaultProps,
  noBackground: false,
  round: false,
  small: false,
};

LinkButton.propTypes = buttonProps;

LinkButton.defaultProps = buttonDefaultProps;

export { Button as default, LinkButton, IconButton };
