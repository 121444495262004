import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  NavigationItem,
  NavigationDropdown,
} from '@moonshineragency/ui/src/components/Navigation/Navigation';

import Container from 'components/Container/Container';

import navigationStyles from '@moonshineragency/ui/src/components/Navigation/Navigation.module.scss';
import styles from '@moonshineragency/ui/src/components/Header/Header.module.scss';

const Header = ({ children, title, className, img, renderHeaderNav }) => (
  <header className={classNames(className, styles.container)}>
    <Container className={styles.wrapper}>
      <a href="/">
        <img className={styles.image} src={img} alt="logo" />
      </a>
      <span className={styles.headline}>{title}</span>
      {renderHeaderNav && (
        <div className="navbar navbar-expand">
          <ul className="navbar-nav">
            <NavigationDropdown
              className={styles.headerNav}
              label="Mr. Electric"
              renderArrow={false}
              icon={<span className="icon user mt-1 mr-3" />}
            >
              <NavigationItem
                className={navigationStyles.menuItem}
                label="Mein Konto"
                icon={<span className="icon cog mt-1 mr-3" />}
              />
              <NavigationItem
                className={navigationStyles.menuItem}
                label="Ausloggen"
                icon={<span className="icon logout mt-1 mr-3" />}
              />
            </NavigationDropdown>
          </ul>
        </div>
      )}
      {children}
    </Container>
  </header>
);

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  renderHeaderNav: PropTypes.bool,
  title: PropTypes.string,
};

Header.defaultProps = {
  className: '',
  title: '',
  children: null,
  renderHeaderNav: false,
};

export default Header;
