import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '@moonshineragency/ui/src/components/Card/Card.module.scss';

const Card = ({ children, className, img, title }) => (
  <div className={classNames(className, styles.container)}>
    {img}
    <div className="card-body">
      {title && <h3 className="card-title text-uppercase">{title}</h3>}
      {children}
    </div>
  </div>
);

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  img: PropTypes.node,
  title: PropTypes.string,
};

Card.defaultProps = {
  className: '',
  img: null,
  children: null,
  title: null,
};

export default Card;
