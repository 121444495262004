import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from '@moonshineragency/ui/src/components/Link/Link';

import Container from 'components/Container/Container';

import styles from 'components/Footer/Footer.module.scss';

const Footer = ({ items, renderStyleguide = true }) => (
  <div className={classNames('py-3', 'mt-4', styles.container)}>
    <Container>
      <ul className={classNames(styles.navList, 'nav d-flex')}>
        {items.map(({ title, link }) => (
          <li className="nav-item" key={title}>
            <u>
              <a className="nav-link text-white font-weight-normal" href={link}>
                {title}
              </a>
            </u>
          </li>
        ))}
        <li
          className={classNames(styles.responsiveItem, 'nav-link text-white')}
        >
          &copy; 2020 Netz NÖ
        </li>
      </ul>
      {renderStyleguide && (
        <ul className="nav ml-3">
          <li className="nav-item">
            <Link className="text-white font-weight-normal" to="/">
              Styleguide
            </Link>
          </li>
        </ul>
      )}
    </Container>
  </div>
);

Footer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, link: PropTypes.string }),
  ),
  renderStyleguide: PropTypes.bool,
};

Footer.defaultProps = {
  items: [],
  renderStyleguide: true,
};

export default Footer;
